import styled from 'styled-components'

const Box = styled.div`
  border: 1px solid #292929;
  border-radius: 20px;
  background: transparent;
  position: relative;
  min-height: 40px;
  padding: 12px;
  margin-bottom: 3px;
`

const EBox = (props: any) => {
  return <Box {...props}>{props.children}</Box>
}

export default EBox
