import { GraphQLClient } from 'graphql-request'

// export const ELDEN_ADDRESS: `0x${string}` = '0x59683019dbA791Bea6085897b191F7854883cAa2' 
// export const ELDEN_ADDRESS: `0x${string}` = '0x9a5E1fe22932a9DB052350468CdCcCc677f076B7'
export const ELDEN_ADDRESS: `0x${string}` = '0xC281162B44cBB56e575051985cD32E571948Ca7a' 
export const SELDEN_ADDRESS: `0x${string}` = '0x98179d697f2cF768C6F8B3f8326BC6AbAf74B675' 
export const ELDEN_MASTER: `0x${string}` = '0xeC7d76A64ccaC0B8555f33fb2808c0dDC5057758' 
export const NFT_POOL_FACTORY_ADDRESS: `0x${string}` = '0x858427C08a3F34A1816F0e1c3BAd226640BA440D' 
export const YIELD_BOOSTER_ADDRESS: `0x${string}` = '0x4f0e81253c7f2083191c711DF4a31E5d8b9c17dc' 
export const DIVIDENDS_ADDRESS: `0x${string}` = '0x6FbEf05BB0D836716C820A6897B03F86003a48Ba' 
export const LAUNCHPAD_ADDRESS: `0x${string}` = '0x8CD9Ae307F743ABaa45f9dedc8adB310ab62E27e' 
export const POSITION_HELPER_ADDRESS: `0x${string}` = '0x67536f968010E533d5f9C407092FbC43750a3AdA' 
export const RUNE_POOL_FACTORY_ADDRESS: `0x${string}` = '0xEE1BD2fe0908dC8B67F67d506740D4D38d25dea8' 


export const ETH_PRICE_FEED: `0x${string}` = '0x639Fe6ab55C921f74e7fac1ee960C0B6293ba612'  
export const POSITIONS_SUBGRAPH = 'https://api.studio.thegraph.com/query/60345/ggtoro-positions-sepolia/version/latest' 

export const positionsSubgraphClient = new GraphQLClient(POSITIONS_SUBGRAPH)