import { ChainId } from '@pancakeswap/chains'
import { supportedChainId } from '@pancakeswap/farms'

export const SUPPORT_ONLY_BSC = [ChainId.BSC]
export const SUPPORT_ONLY_SCROLL = [ChainId.SCROLL_SEPOLIA]
export const SUPPORT_ONLY_BASE = [ChainId.BASE, ChainId.BASE_TESTNET]
export const SUPPORT_ONLY_GOERLI = [ChainId.GOERLI, ChainId.GOERLI] 
export const SUPPORT_ONLY_SEPOLIA = [ChainId.SEPOLIA, ChainId.SEPOLIA] 
export const SUPPORT_ONLY_POLYGON = [ChainId.POLYGON, ChainId.POLYGON]
export const SUPPORT_ONLY_ZKSYNC_TESTNET = [ChainId.ZKSYNC_TESTNET, ChainId.ZKSYNC_TESTNET]  
export const SUPPORT_ONLY_ZKSYNC = [ChainId.ZKSYNC, ChainId.ZKSYNC] 
export const SUPPORT_ONLY_ARBITRUM_ONE = [ChainId.ARBITRUM_ONE, ChainId.ARBITRUM_ONE] 
export const SUPPORT_ZKSYNC_ARB = [ChainId.ZKSYNC,ChainId.ARBITRUM_ONE];
export const SUPPORT_FARMS = supportedChainId
export const SUPPORT_BUY_CRYPTO = [
  ChainId.BSC,
  ChainId.ETHEREUM,
  ChainId.ARBITRUM_ONE,
  ChainId.ZKSYNC,
  ChainId.POLYGON_ZKEVM,
  ChainId.LINEA,
  ChainId.BASE,
  ChainId.GOERLI,
  ChainId.SEPOLIA, 
  ChainId.POLYGON, 
  ChainId.ZKSYNC_TESTNET, 
]

export const LIQUID_STAKING_SUPPORTED_CHAINS = [
  ChainId.BSC,
  ChainId.ETHEREUM,
  ChainId.BSC_TESTNET,
  ChainId.ARBITRUM_GOERLI,
  ChainId.ARBITRUM_ONE,
  ChainId.GOERLI,
  ChainId.SEPOLIA, 
  ChainId.POLYGON, 
  ChainId.ZKSYNC_TESTNET, 
  ChainId.ZKSYNC, 
]
export const FIXED_STAKING_SUPPORTED_CHAINS = [ChainId.BSC, ChainId.GOERLI, ChainId.SEPOLIA , ChainId.POLYGON, ChainId.ZKSYNC_TESTNET , ChainId.ZKSYNC , ChainId.ARBITRUM_ONE ]

export const V3_MIGRATION_SUPPORTED_CHAINS = [ChainId.BSC, ChainId.ETHEREUM, ChainId.SEPOLIA , ChainId.POLYGON , ChainId.ZKSYNC_TESTNET, ChainId.ZKSYNC ,ChainId.ARBITRUM_ONE ]
