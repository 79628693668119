import { useIsMounted } from "@pancakeswap/hooks";
import React from "react";
import { useMatchBreakpoints } from "../../contexts";
import { Box, Flex } from "../Box";
import { Link } from "../Link";
import {
  StyledFooter,
  StyledIconMobileContainer,
  StyledList,
  StyledListItem,
  StyledSocialLinks,
  StyledText,
  StyledToolsContainer,
} from "./styles";

import { vars } from "../../css/vars.css";
import { Button } from "../Button";
import CakePrice from "../CakePrice/CakePrice";
import LangSelector from "../LangSelector/LangSelector";
import { ArrowForwardIcon, LogoIcon, LogoWithTextIcon } from "../Svg";
import Logo from "../../widgets/Menu/components/Logo"
import { ThemeSwitcher } from "../ThemeSwitcher";
import { FooterProps } from "./types";
import { alignContent } from "styled-system";
import styled from "styled-components";


const ResponsiveBox = styled(Box)`
  position: relative;
  left: 40px;
  @media (max-width: 768px) {
    left: -40px;
  }
`;
const MenuItem: React.FC<React.PropsWithChildren<FooterProps>> = ({
  items,
  isDark,
  toggleTheme,
  currentLang,
  langs,
  setLang,
  cakePriceUsd,
  buyCakeLabel,
  buyCakeLink,
  chainId,
  ...props
}) => {
  const isMounted = useIsMounted();
  const { isXl } = useMatchBreakpoints();
  return (
    <StyledFooter
      data-theme="dark"
      p={["40px 16px", null, "56px 40px 32px 40px"]}
      position="relative"
      {...props}
      justifyContent="center"
    >
      <Flex flexDirection="column" width={["100%", null, "1200px;"]}>
        <Flex
          width="100%"
          justifyContent="space-between"
          style={{ gap: "50px" }}
          flexDirection={["column", "column", "column", "column", "row", "row"]}
        >
          <Flex
            width="100%"
            order={[2, null, 1]}
            flexDirection={["column", "column", "column", "column", "row", "row"]}
            justifyContent="space-between"
            alignItems="flex-start"
            mb={["42px", null, "36px"]}
          >
            <Flex flexDirection="column" justifyContent="flex-start" alignItems="center" style={{ gap: "20px"} }>
              <ResponsiveBox>{<Logo href={"/"}/>}</ResponsiveBox>
              <StyledSocialLinks order={[2]} pb={["42px", null, "32px"]} mb={["0", null, "32px"]} />
            </Flex>
            {items?.map((item) => (
              <StyledList key={item.label}>
                <StyledListItem>{item.label}</StyledListItem>
                {item.items?.map(({ label, href, isHighlighted = false }) => (
                  <StyledListItem key={label}>
                    {href ? (
                      <Link
                        data-theme="dark"
                        href={href}
                        target="_blank"
                        rel="noreferrer noopener"
                        color={isHighlighted ? vars.colors.warning : "gray6"}
                        bold={false}
                        style={{ fontSize: "15px", fontWeight: 300 }}
                      >
                        {label}
                      </Link>
                    ) : (
                      <StyledText>{label}</StyledText>
                    )}
                  </StyledListItem>
                ))}
              </StyledList>
            ))}
          </Flex>
        </Flex>
      </Flex>
    </StyledFooter>
  );
};

export default MenuItem;
